import {
  VuexModule,
  Module,
  Action,
  Mutation,
} from 'vuex-module-decorators';

interface SetDialogPayload {
  open: string;
  dialogType: string;
}

@Module({ namespaced: true })
export default class Dialog extends VuexModule {
  public open: string = 'false';

  public dialogType: string = '';

  @Mutation
  public setDialog(payload: SetDialogPayload): void {
    this.dialogType = payload.dialogType;
    this.open = payload.open;
  }

  @Action
  public updateDialog(payload: SetDialogPayload): void {
    console.log('before set Dialog', payload);
    this.context.commit('setDialog', payload);
  }
}
