





















































import { Component, Vue } from 'vue-property-decorator';
import BankService from '@/services/BankService';

const bankService = new BankService();

@Component
export default class CreateBank extends Vue {
  public bank: string = '';

  public accountNumber: string = '';

  public name: string = '';

  public loading: boolean = false;

  async createBank() {
    try {
      this.loading = true;
      await bankService.createBank({
        bank: this.bank,
        // eslint-disable-next-line @typescript-eslint/camelcase
        account_number: this.accountNumber,
        name: this.name,
      });
      this.loading = false;
      this.$store.commit('Snackbar/showNotification', { message: 'ดำเนินการเสร็จสิ้น', error: false });
      this.$router.push('/banks');
    } catch (e) {
      this.loading = false;
      this.$store.commit('Snackbar/showNotification', { message: e.message, error: true });
    }
  }
}
