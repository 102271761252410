var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('default-layout',[_c('h1',{staticClass:"mb-2 font-weight-regular"},[_vm._v(" รายการคืนยอดเสีย ")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('auth',{scopedSlots:_vm._u([{key:"loggedIn",fn:function(ref){
var token = ref.token;
return [_c('v-card',{attrs:{"color":"grey lighten-5","flat":""}},[_c('v-card-title',[_c('base-date-range-search',{ref:"SEARCH",attrs:{"default-date":_vm.range.split(',')},on:{"search":_vm.search}})],1),_c('v-progress-linear',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],attrs:{"indeterminate":"","color":"blue"}}),_c('v-card-text',[_c('base-data-table',{ref:"REFUND_TABLE",attrs:{"data-url":_vm.refundUrl,"request-header":{
                  'authorization': ("Bearer " + token)
                },"headers":[
                  { text: 'ชื่อผู้ใช้', value: 'username' },
                  { text: 'ชื่อบัญชี', value: 'account_name' },
                  { text: 'ยอดฝากทั้งหมด', value: 'current_total_deposit', dataType: 'number' },
                  { text: 'ยอดถอนทั้งหมด', value: 'current_total_withdraw', dataType: 'number' },
                  { text: 'รอบเดือนที่คำนวน', value: 'period' },
                  { text: 'ยอดเสีย', value: 'current_loss', dataType: 'number' },
                  { text: 'ยอดที่จ่ายคืน', value: 'final_refund', dataType: 'number' },
                  { text: 'สร้างเมื่อ', value: 'created_at' },
                  { text: 'แก้ไขเมื่อ', value: 'updated_at' } ],"filters":{
                  range: _vm.range
                }},scopedSlots:_vm._u([{key:"username",fn:function(ref){
                var props = ref.props;
return [_vm._v(" "+_vm._s(_vm._f("hiddenUsername")(props.transaction.user_id.username))+" ")]}},{key:"current_loss",fn:function(ref){
                var props = ref.props;
return [_vm._v(" "+_vm._s(_vm._f("twoPointDecimal")(props.current_loss))+" ")]}},{key:"loss",fn:function(ref){
                var props = ref.props;
return [_vm._v(" "+_vm._s(props.current_loss - props.before_loss)+" ")]}},{key:"account_name",fn:function(ref){
                var props = ref.props;
return [_vm._v(" "+_vm._s(props.transaction.user_detail.account_name)+" ")]}},{key:"final_refund",fn:function(ref){
                var props = ref.props;
return [_vm._v(" "+_vm._s(_vm._f("twoPointDecimal")(props.final_refund))+" ")]}},{key:"created_at",fn:function(ref){
                var props = ref.props;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(props.created_at))+" ")]}},{key:"updated_at",fn:function(ref){
                var props = ref.props;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(props.updated_at))+" ")]}}],null,true)})],1)],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }