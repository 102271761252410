var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('default-layout',[_c('h1',{staticClass:"mb-2 font-weight-regular",staticStyle:{"font-size":"2.4rem"}},[_vm._v(" สรุปยอดการเติมเงินแต่ละ user "+_vm._s(_vm.dateText)+" ")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('auth',{scopedSlots:_vm._u([{key:"loggedIn",fn:function(ref){
var token = ref.token;
return [_c('v-card',{attrs:{"color":"grey lighten-5","flat":""}},[_c('v-card-title',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"9"}},[_c('base-date-range-search',{ref:"SEARCH",attrs:{"default-date":_vm.range.split(',')},on:{"search":_vm.search}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('div',[_c('v-text-field',{directives:[{name:"debounce",rawName:"v-debounce:300ms",value:(_vm.setSearchText),expression:"setSearchText",arg:"300ms"}],attrs:{"label":"กรุณากรอกข้อมูลเพื่อค้นหา","filled":"","rounded":"","dense":"","hint":"ค้นหาได้เฉพาะ เลขบัญชีผู้เล่น ชื่อบัญชี username","persistent-hint":""}})],1)])],1)],1),_c('v-progress-linear',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],attrs:{"indeterminate":""}}),_c('v-card-text',[_c('base-data-table',{ref:"DEPOSIT_RANKING_TABLE",attrs:{"data-url":_vm.reportUrl,"request-header":{
                  'authorization': ("Bearer " + token)
                },"filters":{
                  range: _vm.range,
                  search: _vm.textSearch,
                },"headers":[
                  { text: 'username', value: 'username' },
                  { text: 'ชื่อบัญชี', value: 'account_name' },
                  { text: 'เลขบัญชี', value: 'account_number' },
                  {
                    text: 'จำนวนที่เติม',
                    value: 'total_deposit',
                    sortable: true,
                  } ]},scopedSlots:_vm._u([{key:"username",fn:function(ref){
                var props = ref.props;
return [_vm._v(" "+_vm._s(_vm._f("hiddenUsername")(props.username))+" ")]}}],null,true)})],1)],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }