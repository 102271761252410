




































































































































































































































































































































































































































































































/* eslint-disable @typescript-eslint/camelcase */

import { Component, Vue, Watch } from 'vue-property-decorator';
import BaseDataTable from '@/components/BaseDataTable.vue';
import moment from 'moment';
import Auth from '@/connector/Auth.vue';
import PlayerService from '@/services/PlayerService';
import BankService from '@/services/BankService';
import TransactionService from '@/services/TransactionService';
import PromotionService from '@/services/PromotionService';

const playerService = new PlayerService();
const bankService = new BankService();
const transactionService = new TransactionService();
const promotionService = new PromotionService();

@Component({
  components: {
    BaseDataTable,
    Auth,
  },
})
export default class PlayerList extends Vue {
  public textSearch: string = '';

  public selectedDepositPlayerId: string = '';

  public selectedWithdrawPlayerId: string = '';

  public selectedGiveawayPlayerId: string = '';

  public selectedPasswordChangePlayerId: string = '';

  public selectedBank: { bank: string; account_number: string } = {
    bank: '',
    account_number: '',
  };

  public searchKey: string = 'all';

  public loading: boolean = false;

  public depositDialog: boolean = false;

  public withdrawDialog: boolean = false;

  public giveawayDialog: boolean = false;

  public passwordChangeDialog: boolean = false;

  public amount: number = 0;

  public banks: Array<any> = [];

  public withdrawAmount: number = 0;

  public giveawayAmount: number = 0;

  public selectedBonusUserId: string = '';

  public password: string = '';

  public date: string = this.addZeroPrefix(new Date().getDate());

  public month: string = this.addZeroPrefix(new Date().getMonth() + 1);

  public year: number = new Date().getFullYear();

  public hour: string = this.addZeroPrefix(new Date().getHours());

  public minute: string = this.addZeroPrefix(new Date().getMinutes());

  public selectedGiveawayPromotion: string = '';

  public selectedGiveawayNotifyImage: string = 'bonus-default';

  public giveawayPromotions: Array<any> = [];

  async created() {
    await this.fetchBank();
    await this.fetchAdminGiveawayPromotion();
  }

  @Watch('selectedDepositPlayerId')
  public onSelectedDepositPlayerIdChange(value: string) {
    if (value) {
      this.depositDialog = true;
    }
  }

  @Watch('depositDialog')
  public onDepositDialogChange(value: boolean) {
    if (!value) {
      this.amount = 0;
      this.selectedBank = {
        bank: '',
        account_number: '',
      };
      this.selectedDepositPlayerId = '';
    } else {
      this.date = this.addZeroPrefix(new Date().getDate());
      this.month = this.addZeroPrefix(new Date().getMonth() + 1);
      this.year = new Date().getFullYear();
      this.hour = this.addZeroPrefix(new Date().getHours());
      this.minute = this.addZeroPrefix(new Date().getMinutes());
    }
  }

  @Watch('selectedWithdrawPlayerId')
  public onSelectedWithdrawPlayerIdChange(value: string) {
    if (value) {
      this.withdrawDialog = true;
    }
  }

  @Watch('withdrawDialog')
  public onWithdrawDialogChange(value: boolean) {
    if (!value) {
      this.withdrawAmount = 0;
      this.selectedWithdrawPlayerId = '';
    }
  }

  @Watch('selectedGiveawayPlayerId')
  public onSelectedGiveawayPlayerIdChange(value: string) {
    if (value) {
      this.giveawayDialog = true;
    }
  }

  @Watch('giveawayDialog')
  public onGiveawayDialogChange(value: boolean) {
    if (!value) {
      this.giveawayAmount = 0;
      this.selectedGiveawayPlayerId = '';
    }
  }

  @Watch('selectedPasswordChangePlayerId')
  public onPasswordChangeDialogChange(value: boolean) {
    if (value) {
      this.passwordChangeDialog = true;
    }
  }

  @Watch('passwordChangeDialog')
  public onPasswordChangeDialog(value: boolean) {
    if (!value) {
      this.selectedPasswordChangePlayerId = '';
      this.password = '';
    }
  }

  // eslint-disable-next-line class-methods-use-this
  get usersUrl() {
    return `${process.env.VUE_APP_API_ENDPOINT}/users`;
  }

  public setSearchText(value: string) {
    this.textSearch = value;
  }

  public async fetchBank() {
    const { data } = await bankService.getAllBank();
    this.banks = [...data];
  }

  public async fetchAdminGiveawayPromotion() {
    const { data } = await promotionService.getPromotionFreeCredit();
    if (data) {
      this.giveawayPromotions = [...data];
    }
  }

  async updatePlayerStatus(id: string, status: string) {
    try {
      this.loading = true;
      await playerService.updatePlayer(id, { status });
      this.loading = false;
      (this.$refs.USER_TABLE as Vue & { refreshData: () => void }).refreshData();
      this.$store.commit('Snackbar/showNotification', { message: 'ดำเนินการเสร็จสิ้น', error: false });
    } catch (e) {
      this.loading = false;
      this.$store.commit('Snackbar/showNotification', { message: e.message, error: true });
    }
  }

  async updatePlayerAutoDepositWithdrawToggle(id: string, status: boolean) {
    try {
      this.loading = true;
      await playerService.updatePlayer(id, { auto_deposit_withdraw: status });
      this.loading = false;
      (this.$refs.USER_TABLE as Vue & { refreshData: () => void }).refreshData();
      this.$store.commit('Snackbar/showNotification', { message: 'ดำเนินการเสร็จสิ้น', error: false });
    } catch (e) {
      this.loading = false;
      this.$store.commit('Snackbar/showNotification', { message: e.message, error: true });
    }
  }

  async depositByAdmin() {
    try {
      if (!this.selectedBank.account_number) {
        this.$store.commit('Snackbar/showNotification', { message: 'กรุณาเลือกธนาคารที่ลูกค้าฝาก', error: true });
        return;
      }
      if (this.amount < 1) {
        this.$store.commit('Snackbar/showNotification', { message: 'จำนวนที่ลูกค้าฝากเงินต้องมากกว่า 1', error: true });
        return;
      }
      this.loading = true;
      const requestTime = `${this.year}-${this.month}-${this.date} ${this.hour}:${this.minute}`;
      await transactionService.depositByAdmin({
        to_bank: this.selectedBank.bank,
        to_account_number: this.selectedBank.account_number,
        amount: this.amount,
        user_id: this.selectedDepositPlayerId,
        request_time: moment(requestTime).valueOf(),
      });
      this.$store.commit('Snackbar/showNotification', { message: 'เติมเงินให้ลูกค้าเรียบร้อยแล้ว', error: false });
      this.loading = false;
      this.depositDialog = false;
    } catch (e) {
      this.loading = false;
      this.$store.commit('Snackbar/showNotification', { message: e.message, error: true });
    }
  }

  async withdrawByAdmin() {
    try {
      if (this.withdrawAmount < 1) {
        this.$store.commit('Snackbar/showNotification', { message: 'จำนวนที่ต้องการถอนต้องมากกว่า 1', error: true });
        return;
      }
      this.loading = true;
      await transactionService.withdrawByAdmin({
        user_id: this.selectedWithdrawPlayerId,
        withdrawAmount: this.withdrawAmount,
      });
      this.$store.commit('Snackbar/showNotification', { message: 'ถอนเครดิตลูกค้าเรียบร้อยแล้ว', error: false });
      this.loading = false;
      this.withdrawDialog = false;
    } catch (e) {
      this.loading = false;
      this.$store.commit('Snackbar/showNotification', { message: e.message, error: true });
    }
  }

  async bonus(userId: string, promotionId: string) {
    try {
      this.loading = true;
      await promotionService.giveBonus(userId, promotionId);
      this.$store.commit('Snackbar/showNotification', { message: 'แจกโปรโมชั่นให้ลูกค้าเรียบร้อยแล้ว', error: false });
      this.loading = false;
    } catch (e) {
      this.loading = false;
      this.$store.commit('Snackbar/showNotification', { message: e.message, error: true });
    }
  }

  async updatePassword() {
    try {
      this.loading = true;
      navigator.clipboard.writeText(this.password);
      await playerService.updatePlayer(
        this.selectedPasswordChangePlayerId,
        { password: this.password },
      );
      this.loading = false;
      // eslint-disable-next-line max-len
      this.$store.commit('Snackbar/showNotification', { message: 'เปลี่ยนรหัสผ่านเรียบร้อยแล้ว', error: false });
      this.passwordChangeDialog = false;
    } catch (e) {
      this.loading = false;
      this.$store.commit('Snackbar/showNotification', { message: e.message, error: true });
    }
  }

  // eslint-disable-next-line class-methods-use-this
  get months(): Array<{ text: string; value: string }> {
    return [
      { value: '01', text: 'มกราคม' },
      { value: '02', text: 'กุมภาพันธ์' },
      { value: '03', text: 'มีนาคม' },
      { value: '04', text: 'เมษายน' },
      { value: '05', text: 'พฤษภาคม' },
      { value: '06', text: 'มิถุนายน' },
      { value: '07', text: 'กรกฎาคม' },
      { value: '08', text: 'สิงหาคม' },
      { value: '09', text: 'กันยายน' },
      { value: '10', text: 'ตุลาคม' },
      { value: '11', text: 'พฤศจิกายน' },
      { value: '12', text: 'ธันวาคม' },
    ];
  }

  get days(): Array<string> {
    const endDate = Number(moment(`${this.year}-${this.month}`).endOf('month').format('D'));
    return Array.from({ length: endDate }, (_, i) => this.addZeroPrefix(i + 1));
  }

  // eslint-disable-next-line class-methods-use-this
  get years(): Array<number> {
    const currentYear = new Date().getFullYear();
    return [
      currentYear, currentYear - 1,
    ];
  }

  // eslint-disable-next-line class-methods-use-this
  get hours(): Array<string> {
    return Array.from({ length: 24 }, (_, i) => this.addZeroPrefix(i));
  }

  get minutes(): Array<string> {
    return Array.from({ length: 60 }, (_, i) => this.addZeroPrefix(i));
  }

  // eslint-disable-next-line class-methods-use-this
  addZeroPrefix(value: number): string {
    if (value < 10) {
      return `0${value}`;
    }
    return String(value);
  }
}
