












































































import { Component, Vue } from 'vue-property-decorator';
import BaseDataTable from '@/components/BaseDataTable.vue';
import moment from 'moment';
import Auth from '@/connector/Auth.vue';
import BaseDateRangeSearch from '@/components/BaseDateRangeSearch.vue';

@Component({
  components: {
    BaseDataTable,
    Auth,
    BaseDateRangeSearch,
  },
  filters: {
    twoPointDecimal: (value: any) => Number(value).toFixed(2),
  },
})
export default class PlayerRefundList extends Vue {
  public textSearch: string = '';

  public loading: boolean = false;

  public amount: number = 0;

  public range: string = [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')].toString();

  // eslint-disable-next-line class-methods-use-this
  get refundUrl() {
    return `${process.env.VUE_APP_API_ENDPOINT}/transactions/refund`;
  }

  public setSearchText(value: string) {
    this.textSearch = value;
  }

  async search(data: any) {
    this.range = data;
  }
}
