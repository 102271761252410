/* eslint-disable @typescript-eslint/camelcase */
import { AppService } from './AbstractService';

export default class UserService extends AppService {
  public getAdminById(id: string) {
    return this.makeRequest('GET', `/admins/${id}`);
  }

  public updateAdmin(
    id: string,
    updateData: any,
  ) {
    return this.makeRequest('PUT', `/admins/${id}`, updateData);
  }

  public createAdmin(data: any) {
    return this.makeRequest('POST', '/admins', data);
  }

  public userRegisterSummary(range?: string) {
    return this.makeRequest('GET', `/users/summary?range=${range}`);
  }
}
