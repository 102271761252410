




























































































































































































































































































































import moment from 'moment';
import { Component, Vue } from 'vue-property-decorator';
import BaseLineChart from '@/components/BaseLineChart.vue';
import BasePieChart from '@/components/BasePieChart.vue';
import TransactionService from '@/services/TransactionService';
import UserService from '@/services/UserService';
import BaseDateRangeSearch from '@/components/BaseDateRangeSearch.vue';
import BaseBarChart from '@/components/BaseBarChart.vue';

const transactionService = new TransactionService();
const userService = new UserService();

@Component({
  components: {
    BaseLineChart,
    BasePieChart,
    BaseBarChart,
    BaseDateRangeSearch,
  },
  filters: {
    toPointDecimal: (value: any) => Number(value).toFixed(2),
  },
})
export default class Dashboard extends Vue {
  public dateMenu: boolean = false;

  public range: string = [this.startOfMonth, this.endOfMonth].toString();

  public approvedDepositData: Array<any> = [];

  public approvedWithdrawData: Array<any> = [];

  public registerRawData: Array<object> = [];

  public promotionRawData: Array<any> = [];

  public affiliateRawData: Array<any> = [];

  public betRawData: Array<any> = [];

  public rangeDepositAmountSummary: number = 0;

  public rangeWithdrawAmountSummary: number = 0;

  public todayDepositTransaction: number = 0;

  public todayWithdrawTransaction: number = 0;

  public rangeRegister: number = 0;

  public loading: boolean = false;

  // eslint-disable-next-line class-methods-use-this
  get endOfMonth(): string {
    return moment().endOf('month').format('YYYY-MM-DD');
  }

  // eslint-disable-next-line class-methods-use-this
  get startOfMonth(): string {
    return moment().startOf('month').format('YYYY-MM-DD');
  }

  get formatedRange(): string {
    return this.range.replace(',', ' ถึง ');
  }

  // eslint-disable-next-line class-methods-use-this
  get currentRange(): Array<string> {
    const range = this.range.split(',');
    const start = moment(range[0]);
    const end = moment(range[1]);
    const duration = moment.duration(end.diff(start));
    const diff = duration.asDays();
    const days = [];
    for (let i = 0; i <= diff; i += 1) {
      days.push(moment(range[0]).add(i, 'days').format('YYYY-MM-DD'));
    }
    return days;
  }

  async search(value: string) {
    this.range = value;
    await this.fetchData();
  }

  promotionStatisticData() {
    // PROMOTION DATA
    // eslint-disable-next-line max-len
    const allPromotions = [...new Set(this.promotionRawData.map((data) => data.promotion.promotion_name))];
    const palette = ['#075E54', '#720E9E ', '#FF0000', '#4267B2', '#ff9900', '#FF5722'];
    const dataSet: any = [];
    allPromotions.forEach((promotion, dataIndex) => {
      const currentPromotionData = new Array(this.currentRange.length).fill(0);
      // eslint-disable-next-line max-len
      this.promotionRawData.filter((data) => data.promotion.promotion_name === promotion).forEach((data): any => {
        const index = this.currentRange.findIndex((date) => date === moment(data.created_at).format('YYYY-MM-DD'));
        currentPromotionData[Number(index) - 1] += data.amount;
      });
      dataSet.push({
        data: currentPromotionData,
        label: promotion,
        backgroundColor: palette[dataIndex],
        fill: true,
      });
    });
    // AFFILIATE DATA
    const affiliateDate = new Array(this.currentRange.length).fill(0);
    this.affiliateRawData.forEach((data): any => {
      const index = this.currentRange.findIndex((date) => date === moment(data.created_at).format('YYYY-MM-DD'));
      affiliateDate[Number(index) - 1] += data.amount;
    });
    dataSet.push({
      data: affiliateDate,
      label: 'แนะนำรับ 5%',
      backgroundColor: palette[dataSet.length],
      fill: true,
    });
    return {
      labels: this.currentRange,
      datasets: dataSet,
    };
  }

  transactionStatisticData() {
    console.log('this.currentRange', this.currentRange);
    const depositData = new Array(this.currentRange.length).fill(0);
    const withdrawData = new Array(this.currentRange.length).fill(0);
    console.log('approvedDepositData', this.approvedDepositData);
    this.approvedDepositData.forEach((data): any => {
      // const index = moment(data.created_at).format('D');
      // console.log('index', index);
      const index = this.currentRange.findIndex((date) => date === moment(data.created_at).format('YYYY-MM-DD'));
      console.log('index', index);
      depositData[Number(index) - 1] += data.amount;
    });
    console.log('depositData', depositData);
    this.approvedWithdrawData.forEach((data): any => {
      const index = this.currentRange.findIndex((date) => date === moment(data.created_at).format('YYYY-MM-DD'));
      withdrawData[Number(index) - 1] += data.amount;
    });
    const data = {
      labels: this.currentRange,
      datasets: [{
        data: [...depositData],
        borderColor: '#E91E63',
        fill: true,
        label: 'ยอดฝาก',
      }, {
        data: [...withdrawData],
        borderColor: '#9C27B0',
        fill: true,
        label: 'ยอดถอน',
      }],
    };
    return data;
  }

  registerStatisticData() {
    const registerStatisticData = new Array(31).fill(0);
    this.registerRawData.forEach((data: any) => {
      const index = moment(data.created_at).day();
      registerStatisticData[Number(index)] += 1;
    });
    const data = {
      labels: this.currentRange,
      datasets: [{
        data: registerStatisticData,
        borderColor: '#E91E63',
        fill: true,
        label: 'จำนวนสมาชิก',
      }],
    };
    return data;
  }

  reachableStatic() {
    const registerStatisticData = new Array(6).fill(0);
    this.registerRawData.forEach((data: any) => {
      if (data.known_us_from === 'sms') registerStatisticData[0] += 1;
      if (data.known_us_from === 'search_engine') registerStatisticData[1] += 1;
      if (data.known_us_from === 'youtube') registerStatisticData[2] += 1;
      if (data.known_us_from === 'facebook') registerStatisticData[3] += 1;
      if (data.known_us_from === 'friend') registerStatisticData[4] += 1;
      if (data.known_us_from === 'agent') registerStatisticData[5] += 1;
    });
    const data = {
      datasets: [{
        data: registerStatisticData,
        backgroundColor: ['#075E54', '#720E9E ', '#FF0000', '#4267B2', '#ff9900', '#FF5722'],
      }],
      labels: [
        'sms',
        'search_engine',
        'youtube',
        'facebook',
        'friend',
        'agent',
      ],
    };
    return data;
  }

  async fetchData() {
    this.loading = true;
    const { currentRange } = this;
    const { data: depositRawData } = await transactionService.getTransactionSummaryByType('deposit', `${currentRange[0]},${currentRange[currentRange.length - 1]}`);
    const { data: withdrawRawData } = await transactionService.getTransactionSummaryByType('withdraw', `${currentRange[0]},${currentRange[currentRange.length - 1]}`);
    const { data: promotionRawData } = await transactionService.getTransactionSummaryByType('promotion', `${currentRange[0]},${currentRange[currentRange.length - 1]}`);
    const { data: affiliateRawData } = await transactionService.getTransactionSummaryByType('affiliate', `${currentRange[0]},${currentRange[currentRange.length - 1]}`);
    const { data: registeredUser } = await userService.userRegisterSummary(this.range);
    this.registerRawData = [...registeredUser];
    this.rangeRegister = registeredUser.length;
    // DEPOSIT SECTION
    this.approvedDepositData = depositRawData.filter((depositData: any) => depositData.request_id);
    this.todayDepositTransaction = depositRawData.length;
    this.rangeDepositAmountSummary = depositRawData
      .filter((depositData: any) => depositData.request_id)
      .reduce((acc: number, current: any) => acc + current.amount, 0);
    // WITHDRAW SECTION
    // eslint-disable-next-line max-len
    this.approvedWithdrawData = withdrawRawData.filter((withdrawData: any) => withdrawData.request_id);
    this.todayWithdrawTransaction = withdrawRawData.length;
    this.rangeWithdrawAmountSummary = withdrawRawData
      .filter((withdrawData: any) => withdrawData.request_id)
      .reduce((acc: number, current: any) => acc + current.amount, 0);

    this.promotionRawData = [...promotionRawData];
    this.affiliateRawData = [...affiliateRawData];
    console.log('promotionRawData', promotionRawData);
    console.log('affiliateRawData', affiliateRawData);
    this.promotionStatisticData();
    this.loading = false;
  }

  async created() {
    await this.fetchData();
  }
}
