





















































import { Component, Vue } from 'vue-property-decorator';
import AuthService from '@/services/AuthService';

const authService = new AuthService();

@Component
export default class TokenManage extends Vue {
  public tokens: Array<any> = [];

  public loading: boolean = false;

  public async revokeToken(id: string) {
    try {
      this.loading = true;
      // eslint-disable-next-line @typescript-eslint/camelcase
      await authService.revoke({ user_id: id });
      await this.getActiveToken();
      this.loading = false;
      this.$store.commit('Snackbar/showNotification', { message: 'ดำเนินการเสร็จสิ้น', error: false });
    } catch (e) {
      this.$store.commit('Snackbar/showNotification', { message: e.message, error: true });
    }
  }

  public async getActiveToken() {
    const tokens = await authService.getActiveTokenList();
    this.tokens = [...tokens];
  }

  async created() {
    await this.getActiveToken();
  }
}
