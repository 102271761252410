










































import { Component, Vue } from 'vue-property-decorator';
import PromotionService from '@/services/PromotionService';
import PromotionForm from '@/components/PromotionForm.vue';

const promotionService = new PromotionService();

@Component({
  components: {
    PromotionForm,
  },
})
export default class EditPromotion extends Vue {
  public promotionData: any = {};

  public loading: boolean = false;

  async created() {
    try {
      const { params } = this.$route;
      const { data } = await promotionService.getPromotionById(params.id);
      console.log('promotionData', data);
      this.promotionData = { ...data };
    // eslint-disable-next-line no-empty
    } catch (e) {}
  }

  async editPromotion() {
    try {
      this.loading = true;
      // eslint-disable-next-line max-len
      const data = (this.$refs.PROMOTION_FORM as Vue & { getPromotionFormData: () => void }).getPromotionFormData();
      console.log('data', data);
      await promotionService.updatePromotion(this.$route.params.id, data);
      this.loading = false;
      this.$store.commit('Snackbar/showNotification', { message: 'สร้างโปรโมชั่นเรียบร้อยแล้ว', error: false });
      this.$router.push('/promotions');
    } catch (e) {
      this.loading = false;
      this.$store.commit('Snackbar/showNotification', { message: e.message, error: true });
    }
  }
}
