























































import { Component, Vue } from 'vue-property-decorator';
import UserService from '@/services/UserService';
import UserForm from '@/components/UserForm.vue';

const userService = new UserService();

@Component({
  components: {
    UserForm,
  },
})
export default class EditUser extends Vue {
  public userData: any = {};

  public loading: boolean = false;

  async created() {
    const { data } = await userService.getAdminById(this.$route.params.id);
    console.log('userData', data);
    this.userData = { ...data };
  }

  async updateAdmin() {
    try {
      this.loading = true;
      // eslint-disable-next-line max-len
      const data = (this.$refs.USER_FORM as Vue & { getUserFormData: () => void }).getUserFormData();
      await userService.updateAdmin(this.$route.params.id, data);
      this.loading = false;
      this.$store.commit('Snackbar/showNotification', { message: 'ดำเนินการเสร็จสิ้น', error: false });
      this.$router.push('/admins');
    } catch (e) {
      this.loading = false;
      this.$store.commit('Snackbar/showNotification', { message: e.message, error: true });
    }
  }
}
