<template>
  <default-layout>
    <v-row align="center">
      <div class="my-5">
        <h1 class="font-weight-regular">
          รายละเอียดโบนัสของสมาชิก
        </h1>
        <span>ทดสอบ ระบบ</span>
      </div>
      <v-spacer />
      <v-btn
        color="blue"
        dark
        depressed
        :to="{ name: 'AffiliateUserList' }"
      >
        <v-icon
          left
          small
        >
          fas fa-arrow-left
        </v-icon>
        ย้อนกลับ
      </v-btn>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title>
            <v-spacer />
            <div class="d-flex">
              <div style="width: 200px;">
                <v-select
                  label="สมาชิกชั้นที่ 2"
                  filled
                  rounded
                  dense
                  hide-details
                />
              </div>
              <div class="mx-2" style="width: 200px;">
                <v-select
                  label="สมาชิกชั้นที่ 3"
                  filled
                  rounded
                  dense
                  hide-details
                />
              </div>
              <div style="width: 200px;">
                <v-select
                  label="สมาชิกชั้นที่ 4"
                  filled
                  rounded
                  dense
                  hide-details
                />
              </div>
            </div>
          </v-card-title>
          <v-simple-table>
            <thead>
              <tr>
                <th>รหัส</th>
                <th>ชื่อ</th>
                <th>ชื่อผู้ใช้</th>
                <th>สมาชิกชั้นที่</th>
                <th>สถานะ</th>
                <th>สมัครเมื่อ</th>
                <th>โบนัสรวม</th>
                <th>โบนัสคงเหลือ</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1xas34n_lkubqwefduen</td>
                <td>ทดสอบ ระบบ2</td>
                <th>2</th>
                <td>test</td>
                <td>
                  <span class="green--text">
                    เปิดใช้งาน
                  </span>
                </td>
                <td>2021-03-13 00:00:00</td>
                <td>1000000</td>
                <td>100</td>
              </tr>
              <tr>
                <td>2xas34n_lkubqwefduen</td>
                <td>ทดสอบ ระบบ3</td>
                <th>3</th>
                <td>test2</td>
                <td>
                  <span class="green--text">
                    เปิดใช้งาน
                  </span>
                </td>
                <td>2021-03-13 00:00:01</td>
                <td>10000</td>
                <td>100</td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-card>
      </v-col>
    </v-row>
  </default-layout>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class AffiliateUserListDetail extends Vue {}
</script>

<style>

</style>
