var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('default-layout',[_c('h1',{staticClass:"mb-2 font-weight-regular",staticStyle:{"font-size":"2.4rem"}},[_vm._v(" รายการวางเดิมพันประจำวันที่ "+_vm._s(_vm.dateText)+" ")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-card',{attrs:{"dark":"","color":"green","flat":""}},[_c('div',{staticClass:"pa-5"},[_c('center',[_c('span',[_vm._v("ยอดเดิมพันทั้งหมด")]),_c('h1',[_vm._v(" "+_vm._s(_vm._f("numberWithSeperator")(_vm.amount))+" ")]),_c('span',[_vm._v("บาท")])])],1)])],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-card',{attrs:{"dark":"","color":"primary","flat":""}},[_c('div',{staticClass:"pa-5"},[_c('center',[_c('span',[_vm._v("จำนวนรายการ")]),_c('h1',[_vm._v(" "+_vm._s(_vm.transactions)+" ")]),_c('span',[_vm._v("รายการ")])])],1)])],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('auth',{scopedSlots:_vm._u([{key:"loggedIn",fn:function(ref){
var token = ref.token;
return [_c('v-card',{attrs:{"color":"grey lighten-5","flat":""}},[_c('v-card-title',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"9"}},[_c('base-date-range-search',{ref:"SEARCH",attrs:{"default-date":_vm.range.split(',')},on:{"search":_vm.search}})],1),_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('div',[_c('v-text-field',{directives:[{name:"debounce",rawName:"v-debounce:300ms",value:(_vm.setSearchText),expression:"setSearchText",arg:"300ms"}],attrs:{"label":"กรุณากรอกข้อมูลเพื่อค้นหา","filled":"","rounded":"","dense":"","hint":"ค้นหาได้เฉพาะ เลขบัญชีผู้เล่น ชื่อบัญชี username","persistent-hint":""}})],1)])],1)],1),_c('v-card-text',[_c('base-data-table',{ref:"TRANSACTION_TABLE",attrs:{"data-url":_vm.transactionUrl,"request-header":{
                  'authorization': ("Bearer " + token)
                },"headers":[
                  { text: 'username', value: 'user_id' },
                  { text: 'ชื่อบัญชี', value: 'account_name' },
                  { text: 'จำนวนเงิน', value: 'amount', dataType: 'number' },
                  { text: 'รายการที่ชนะ', value: 'reward_detail' },
                  { text: 'รายการที่เสมอ', value: 'tie_detail' },
                  { text: 'จำนวนเงินก่อนทำธุรกรรม', value: 'before', dataType: 'number' },
                  { text: 'จำนวนเงินหลังทำธุรกรรม', value: 'after', dataType: 'number' },
                  { text: 'สร้างเมื่อ', value: 'created_at' },
                  { text: 'แก้ไขเมื่อ', value: 'updated_at' } ],"filters":{
                  range: _vm.range,
                  type: 'REWARD',
                  search: _vm.textSearch,
                }},scopedSlots:_vm._u([{key:"user_id",fn:function(ref){
                var user_id = ref.props.user_id;
return [_c('v-btn',{attrs:{"v-if":user_id,"text":"","to":{ name: 'PlayerDetail', params: { id: user_id._id } }}},[_vm._v(" "+_vm._s(user_id.username)+" ")])]}},{key:"account_name",fn:function(ref){
                var user_id = ref.props.user_id;
return [_c('div',[(user_id)?_c('span',[_vm._v(" "+_vm._s(user_id.account_name)+" ")]):_c('span',[_vm._v(" - ")])])]}},{key:"reward_detail",fn:function(ref){
                var props = ref.props;
return _vm._l((props.reward_detail.win.chip),function(win,index){return _c('span',{key:("win-" + index)},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(win.name)+" ("+_vm._s(win.bet)+"), ")])]}}],null,true)},[_c('span',[_vm._v("วางเดิมพัน: "+_vm._s(win.name))]),_c('br'),_c('span',[_vm._v("เดิมพันที่: "+_vm._s(win.bet))]),_c('br'),_c('span',[_vm._v("อัตราจ่าย: "+_vm._s(win.rate)+" เท่า")]),_c('br'),_c('span',[_vm._v("ลูกค้ากำไร: "+_vm._s(win.profit)+" บาท")])])],1)})}},{key:"tie_detail",fn:function(ref){
                var props = ref.props;
return _vm._l((props.reward_detail.tie.chip),function(tie,index){return _c('span',{key:("tie-" + index)},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(tie.name)+" "+_vm._s(tie.bet)+", ")])]}}],null,true)},[_c('span',[_vm._v("วางเดิมพัน: "+_vm._s(tie.bet))]),_c('span',[_vm._v("คืนเงิน: "+_vm._s(tie.bet))]),_c('br'),_c('span',[_vm._v("*** หากผลเสมอระบบจะทำการคืนเงินแก่ผู้เล่น ***")])])],1)})}},{key:"round",fn:function(ref){
                var props = ref.props;
return [_vm._v(" "+_vm._s(props.bet_detail.round)+" ")]}},{key:"created_at",fn:function(ref){
                var props = ref.props;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(props.created_at))+" ")]}},{key:"updated_at",fn:function(ref){
                var props = ref.props;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(props.updated_at))+" ")]}}],null,true)})],1)],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }