













































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import BaseDataTable from '@/components/BaseDataTable.vue';
import moment from 'moment';
import Auth from '@/connector/Auth.vue';
import BaseDateRangeSearch from '@/components/BaseDateRangeSearch.vue';
import TransactionService from '@/services/TransactionService';

const transactionService = new TransactionService();

@Component({
  components: {
    BaseDataTable,
    Auth,
    BaseDateRangeSearch,
  },
})
export default class Summary extends Vue {
  public loading: boolean = false;

  public range: string = [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')].toString();

  public summary = {};

  async search(data: any) {
    this.range = data;
  }

  // @Watch('range')
  // async onRangeChange() {
  //   await this.getReportSummary();
  // }

  get dateText() {
    const date = this.range.split(',');
    return `${date[0]} - ${date[1]}`;
  }

  // eslint-disable-next-line class-methods-use-this
  get reportUrl() {
    return `${process.env.VUE_APP_API_ENDPOINT}/transactions/report`;
  }

  // eslint-disable-next-line class-methods-use-this
  public getColor(a: number, b: number) {
    const sum = a - b;
    if (sum > 0) return 'green';
    return 'red';
  }

  // public async getReportSummary() {
  //   const { data } = await transactionService.getReportSummary(this.range);
  //   this.summary = { ...data };
  // }

  // async created() {
  //   await this.getReportSummary();
  // }
}
