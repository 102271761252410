




































































































































































import { Component, Vue } from 'vue-property-decorator';
import BaseDataTable from '@/components/BaseDataTable.vue';
import moment from 'moment';
import Auth from '@/connector/Auth.vue';
import BaseDateRangeSearch from '@/components/BaseDateRangeSearch.vue';

@Component({
  components: {
    BaseDataTable,
    Auth,
    BaseDateRangeSearch,
  },
})
export default class Share extends Vue {
  public loading: boolean = false;

  public date: string = moment().format('YYYY-MM');

  public type: string = '';

  // eslint-disable-next-line class-methods-use-this
  get monthsOption() {
    const currentMonth = moment();
    const maximumData = moment().subtract(6, 'month');
    const options = [];
    while (currentMonth > maximumData) {
      options.push(currentMonth.format('YYYY-MM'));
      currentMonth.subtract(1, 'month');
    }
    return options;
  }

  // eslint-disable-next-line class-methods-use-this
  get shareUrl() {
    return `${process.env.VUE_APP_API_ENDPOINT}/shares`;
  }

  // eslint-disable-next-line class-methods-use-this
  public getColor(amount: number) {
    if (amount > 0) return 'green';
    return 'red';
  }

  // eslint-disable-next-line class-methods-use-this
  public formatDate(date: string) {
    return moment(date).format('DD/MM/YYYY');
  }
}
