/* eslint-disable */
import Vue from 'vue';
import Vuex from 'vuex';
import Snackbar from '@/store/modules/snackbar';
import Auth from '@/store/modules/auth';
import Dialog from '@/store/modules/dialog';
import Notification from '@/store/modules/notification';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    Snackbar,
    Auth,
    Dialog,
    Notification,
  },
});
