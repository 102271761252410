<script>
import { Pie, mixins } from 'vue-chartjs';

const { reactiveProp } = mixins;

export default {
  extends: Pie,
  mixins: [reactiveProp],
  props: {
    chartData: {
      type: Object,
      default: () => ({
        datasets: [{
          data: [0, 0, 0, 0, 0, 0],
          backgroundColor: ['#075E54', '#720E9E ', '#FF0000', '#4267B2', '#ff9900', '#FF5722'],
        }],
        labels: [
          'sms',
          'search_engine',
          'youtube',
          'facebook',
          'friend',
          'agent',
        ],
      }),
    },
    options: {
      type: Object,
      default: () => ({
        maintainAspectRatio: false,
      }),
    },
  },

  mounted() {
    this.renderChart(this.chartData, this.options);
  },
};
</script>
