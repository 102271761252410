




























































































































































































































































































































































import moment from 'moment';
import { Component, Vue, Watch } from 'vue-property-decorator';
import BaseDataTable from '@/components/BaseDataTable.vue';
import Auth from '@/connector/Auth.vue';
import BaseDateRangeSearch from '@/components/BaseDateRangeSearch.vue';
import TransactionService from '@/services/TransactionService';
import Notification from '@/connector/Notification.vue';

const transactionService = new TransactionService();

@Component({
  components: {
    BaseDataTable,
    BaseDateRangeSearch,
    Auth,
    Notification,
  },
})
export default class DepositRequest extends Vue {
  public loading: boolean = false;

  // eslint-disable-next-line max-len
  public range: string = [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')].toString();

  public textSearch: string = '';

  public totalTransaction: number = 0;

  public totalAmount: number = 0;

  public pendingAmount: number = 0;

  public pendingTransction: number = 0;

  public rejectedAmount: number = 0;

  public rejectedTransction: number = 0;

  public approvedAmount: number = 0;

  public approvedTransction: number = 0;

  // eslint-disable-next-line class-methods-use-this
  get depositListUrl() {
    return `${process.env.VUE_APP_API_ENDPOINT}/transactions/admin/deposit`;
  }

  // eslint-disable-next-line @typescript-eslint/camelcase
  async updateDepositReuqest(request_id: string, status: string) {
    try {
      this.loading = true;
      // eslint-disable-next-line @typescript-eslint/camelcase
      await transactionService.updateDepositRequest({ request_id, status });
      (this.$refs.DEPOSIT_TABLE as Vue & { refreshData: () => void }).refreshData();
      this.loading = false;
      this.$store.commit('Snackbar/showNotification', { message: 'ดำเนินการเสร็จสิ้น', error: false });
    } catch (e) {
      this.loading = false;
      this.$store.commit('Snackbar/showNotification', { message: e.message, error: true });
    }
  }

  // eslint-disable-next-line class-methods-use-this
  async search(data: any) {
    this.range = data;
  }

  @Watch('range')
  async onRangeChange() {
    await this.getDepositSummary();
  }

  @Watch('textSearch')
  async onTextSearchChange() {
    await this.getDepositSummary();
  }

  // eslint-disable-next-line class-methods-use-this
  countRequest(type: string, data: any) {
    console.log('data', data);
    const transactions = data
      .filter((transaction: { status: string }) => transaction.status === type);
    return {
      total: transactions.length,
      amount: transactions.reduce((acc: number, current: any) => acc + current.amount, 0),
    };
  }

  async getDepositSummary() {
    const { data } = await transactionService.getRequestSummary('deposit', this.range, this.textSearch);
    console.log('data', data);
    const pendingData = this.countRequest('PENDING', data);
    const rejectedData = this.countRequest('REJECTED', data);
    const approvedData = this.countRequest('APPROVED', data);
    this.totalTransaction = data.length;
    this.totalAmount = data
      .reduce((acc: number, current: any) => acc + current.amount, 0);
    this.pendingAmount = pendingData.amount;
    this.pendingTransction = pendingData.total;
    this.rejectedAmount = rejectedData.amount;
    this.rejectedTransction = rejectedData.total;
    this.approvedAmount = approvedData.amount;
    this.approvedTransction = approvedData.total;
  }

  public setSearchText(value: string) {
    this.textSearch = value;
  }

  // eslint-disable-next-line class-methods-use-this
  public reloadPage() {
    window.location.reload();
  }

  get dateText() {
    const date = this.range.split(',');
    return `${date[0]} - ${date[1]}`;
  }

  async created() {
    await this.getDepositSummary();
    this.$store.dispatch('Notification/removeRequestState', 'deposit');
  }
}
