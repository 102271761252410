<script>
import { Bar, mixins } from 'vue-chartjs';

const { reactiveProp } = mixins;

export default {
  extends: Bar,
  mixins: [reactiveProp],
  props: {
    chartData: {
      type: Object,
      default: () => ({}),
    },
    options: {
      type: Object,
      default: () => ({
        maintainAspectRatio: false,
      }),
    },
  },

  mounted() {
    this.renderChart(this.chartData, this.options);
  },
};
</script>
