












































import { Component, Vue } from 'vue-property-decorator';

@Component
export default class BaseConfirmDialog extends Vue {
  public dialog: boolean = false;

  public title: string = '';

  public message: string = '';

  public reslove: any;

  public reject: any;

  public open(data: { title: string; message: string }) {
    this.title = data.title;
    this.message = data.message;
    this.dialog = true;
    return new Promise((resolve) => {
      this.reslove = resolve;
    });
  }

  public confirm() {
    this.dialog = false;
    this.reslove(true);
  }

  refuse() {
    this.dialog = false;
    this.reslove(false);
  }
}
