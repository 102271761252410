






































































import { Component, Vue } from 'vue-property-decorator';
import AuthService from '@/services/AuthService';

const authService = new AuthService();

@Component
export default class ChangePassword extends Vue {
  public loading: boolean = false;

  public oldPassword: string = '';

  public newPassword: string = '';

  public confirmNewPassword: string = '';

  async changePassword() {
    try {
      this.loading = true;
      await authService.changePassword(
        this.oldPassword,
        this.newPassword,
        this.confirmNewPassword,
      );
      this.loading = false;
      this.$store.commit('Snackbar/showNotification', { message: 'เปลี่ยนรหัสผ่านแล้ว', error: false });
      this.$router.push('/deposit');
    } catch (e) {
      this.$store.commit('Snackbar/showNotification', { message: e.message, error: true });
      this.loading = false;
    }
  }
}
