import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
// eslint-disable-next-line import/no-cycle
import store from '@/store';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Login',
    component: () => import('@/pages/Login.vue'),
  },
  {
    path: '/deposit',
    name: 'DepositRequest',
    component: () => import('@/pages/DepositRequest.vue'),
    meta: {
      requiredLogin: true,
    },
  },
  {
    path: '/withdraw',
    name: 'WithdrawRequest',
    component: () => import('@/pages/WithdrawRequest.vue'),
    meta: {
      requiredLogin: true,
    },
  },
  {
    path: '/transaction',
    name: 'Transaction',
    component: () => import('@/pages/Transaction.vue'),
    meta: {
      requiredLogin: true,
    },
  },
  {
    path: '/deposit-transaction',
    name: 'DepositTransaction',
    component: () => import('@/pages/DepositTransaction.vue'),
    meta: {
      requiredLogin: true,
    },
  },
  {
    path: '/withdraw-transaction',
    name: 'WithdrawTransaction',
    component: () => import('@/pages/WithdrawTransaction.vue'),
    meta: {
      requiredLogin: true,
    },
  },
  {
    path: '/user-deposit-ranking',
    name: 'UserDepositRanking',
    component: () => import('@/pages/DepositRanking.vue'),
    meta: {
      requiredLogin: true,
    },
  },
  {
    path: '/admins',
    name: 'AdminList',
    component: () => import('@/pages/UserList.vue'),
    meta: {
      requiredLogin: true,
      super: true,
    },
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import('@/pages/Dashboard.vue'),
    meta: {
      requiredLogin: true,
    },
  },
  // {
  //   path: '/player-login-history',
  //   name: 'PlayerLoginHistory',
  //   component: () => import('@/pages/PlayerLoginHistory.vue'),
  //   meta: {
  //     requiredLogin: true,
  //   },
  // },
  {
    path: '/admins/create',
    name: 'CreateAdmin',
    component: () => import('@/pages/CreateUser.vue'),
    meta: {
      requiredLogin: true,
      super: true,
    },
  },
  {
    path: '/admins/:id',
    name: 'EditAdmin',
    component: () => import('@/pages/EditUser.vue'),
    meta: {
      requiredLogin: true,
      super: true,
    },
  },
  {
    path: '/change-password',
    name: 'ChangePassword',
    component: () => import('@/pages/ChangePassword.vue'),
    meta: {
      requiredLogin: true,
    },
  },
  {
    path: '/banks',
    name: 'BankList',
    component: () => import('@/pages/BankList.vue'),
    meta: {
      requiredLogin: true,
    },
  },
  {
    path: '/banks/create',
    name: 'CreateBank',
    component: () => import('@/pages/CreateBank.vue'),
    meta: {
      requiredLogin: true,
    },
  },
  {
    path: '/banks/:id',
    name: 'EditBank',
    component: () => import('@/pages/EditBank.vue'),
    meta: {
      requiredLogin: true,
    },
  },
  {
    path: '/promotions',
    name: 'PromotionList',
    component: () => import('@/pages/PromotionList.vue'),
    meta: {
      requiredLogin: true,
    },
  },
  {
    path: '/promotions/create',
    name: 'CreatePromotion',
    component: () => import('@/pages/CreatePromotion.vue'),
    meta: {
      requiredLogin: true,
    },
  },
  {
    path: '/promotions/:id',
    name: 'EditPromotion',
    component: () => import('@/pages/EditPromotion.vue'),
    meta: {
      requiredLogin: true,
    },
  },
  {
    path: '/affiliate-setting',
    name: 'AffiliateSetting',
    component: () => import('@/pages/AffiliateSetting.vue'),
    meta: {
      requiredLogin: true,
    },
  },
  {
    path: '/affiliate-userlist/:id',
    name: 'AffiliateUserListDetail',
    component: () => import('@/pages/AffiliateUserListDetail.vue'),
    meta: {
      requiredLogin: true,
    },
  },
  {
    path: '/player',
    name: 'PlayerList',
    component: () => import('@/pages/PlayerList.vue'),
    meta: {
      requiredLogin: true,
    },
  },
  {
    path: '/player/:id',
    name: 'EditPlayer',
    component: () => import('@/pages/EditPlayer.vue'),
    meta: {
      requiredLogin: true,
    },
  },
  {
    path: '/player/:id/show',
    name: 'PlayerDetail',
    component: () => import('@/pages/PlayerDetail.vue'),
    meta: {
      requiredLogin: true,
    },
  },
  {
    path: '/token-manage',
    name: 'TokenManage',
    component: () => import('@/pages/TokenManage.vue'),
    meta: {
      requiredLogin: true,
    },
  },
  {
    path: '/promotion-transaction',
    name: 'PromotionTransaction',
    component: () => import('@/pages/PromotionTransactionList.vue'),
    meta: {
      requiredLogin: true,
    },
  },
  {
    path: '/bet-transaction',
    name: 'BetTransaction',
    component: () => import('@/pages/BetTransaction.vue'),
    meta: {
      requiredLogin: true,
    },
  },
  {
    path: '/reward-transaction',
    name: 'RewardTransaction',
    component: () => import('@/pages/RewardTransaction.vue'),
    meta: {
      requiredLogin: true,
    },
  },
  {
    path: '/adjustment-transaction',
    name: 'AdjustmentTransaction',
    component: () => import('@/pages/AdjustmentTransaction.vue'),
    meta: {
      requiredLogin: true,
    },
  },
  {
    path: '/refund-transaction',
    name: 'RefundTransaction',
    component: () => import('@/pages/PlayerRefundList.vue'),
    meta: {
      requiredLogin: true,
    },
  },
  {
    path: '/summary',
    name: 'Summary',
    component: () => import('@/pages/Summary.vue'),
    meta: {
      requiredLogin: true,
    },
  },
  {
    path: '/share',
    name: 'Share',
    component: () => import('@/pages/Share.vue'),
    meta: {
      requiredLogin: true,
    },
  },
  {
    path: '/share/:id',
    name: 'ShareDetail',
    component: () => import('@/pages/ShareDetail.vue'),
    meta: {
      requiredLogin: true,
    },
  },
  {
    path: '/setting',
    name: 'SystemSetting',
    component: () => import('@/pages/SystemSetting.vue'),
    meta: {
      requiredLogin: true,
    },
  },
  {
    path: '/popular-games',
    name: 'PopularGames',
    component: () => import('@/pages/PopularGames.vue'),
    meta: {
      requiredLogin: true,
    },
  },
  {
    path: '/not-allow',
    name: 'NotAllow',
    component: () => import('@/pages/NotAllow.vue'),
    meta: {
      requiredLogin: true,
    },
  },
  {
    path: '/win-lose-summary',
    name: 'WinLoseSummary',
    component: () => import('@/pages/WinLoseSummary.vue'),
    meta: {
      requiredLogin: true,
    },
  },
  // {
  //   path: '/follow-up',
  //   name: 'FollowUpPlayer',
  //   component: () => import('@/pages/FollowUpPlayer.vue'),
  //   meta: {
  //     requiredLogin: true,
  //   },
  // },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  console.log(to.name);
  if (store.state.Auth.isLoggedIn && to.name === 'Login') {
    const fromPage = from.name as string | undefined;
    next({ name: fromPage });
    return;
  }
  if (to.meta.requiredLogin) {
    if (store.state.Auth.isLoggedIn) {
      if (to.name) {
        if (store.state.Auth.permissions[to.name]) {
          next();
          return;
        }
        next({ name: 'NotAllow' });
        return;
      }
      next();
      return;
    }
    next({ name: 'Login' });
    return;
    // if (store.state.Auth.isLoggedIn && !to.meta.super) {
    //   next();
    //   return;
    // }
    // if (store.state.Auth.isLoggedIn && to.meta.super) {
    //   if (store.state.Auth.username === 'superadmin') {
    //     next();
    //     return;
    //   }
    //   return;
    // }
    // next('/');
  }
  next();
});

export default router;
