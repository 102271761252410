































































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import BaseDataTable from '@/components/BaseDataTable.vue';
import moment from 'moment';
import Auth from '@/connector/Auth.vue';
import TransactionService from '@/services/TransactionService';
import BaseDateRangeSearch from '@/components/BaseDateRangeSearch.vue';
import BaseConfirmDialog from '@/components/BaseConfirmDialog.vue';

const transactionService = new TransactionService();

@Component({
  components: {
    BaseDataTable,
    BaseDateRangeSearch,
    Auth,
    BaseConfirmDialog,
  },
})
export default class Transaction extends Vue {
  public loading: boolean = false;

  public textSearch: string = '';

  public provider: any = '';

  public profit: number = 0;

  public sumBet: number = 0;

  public sumReward: number = 0;

  public sumSeize: number = 0;

  // eslint-disable-next-line max-len
  public range: any = [moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')].toString();

  async search(data: any) {
    this.range = data;
  }

  public setSearchText(value: string) {
    this.textSearch = value;
  }

  @Watch('textSearch')
  async onTextSearchChange() {
    await this.getTransactionSummary();
  }

  @Watch('range')
  async onRangeChange() {
    await this.getTransactionSummary();
  }

  // eslint-disable-next-line class-methods-use-this
  get transactionUrl() {
    return `${process.env.VUE_APP_API_ENDPOINT}/transactions/win-loss`;
  }

  get dateText() {
    const date = this.range.split(',');
    return `${date[0]} - ${date[1]}`;
  }

  async getTransactionSummary() {
    try {
      await this.fetchSummary();
    } catch (e) {
      this.$store.commit('Snackbar/showNotification', { message: e.message, error: true });
    }
  }

  async created() {
    await this.fetchSummary();
  }

  async fetchSummary() {
    const { data } = await transactionService.getWinLoseSummary({
      range: this.range,
      game: this.textSearch,
      provider: this.provider,
    });
    this.profit = data.profit;
    this.sumBet = data.sum_bet;
    this.sumReward = data.sum_reward;
    this.sumSeize = data.sum_seize;
  }
}
