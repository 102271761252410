






















































import { Component, Vue } from 'vue-property-decorator';
import UserService from '@/services/UserService';
import UserForm from '@/components/UserForm.vue';

const userService = new UserService();

@Component({
  components: {
    UserForm,
  },
})
export default class CreateUser extends Vue {
  public name: string = '';

  public password: string = '';

  public username: string = '';

  public loading: boolean = false;

  // async created() {
  //   const { data } = await userService.getAdminById(this.$route.params.id);
  //   this.name = data.name;
  //   this.username = data.username;
  // }

  async createAdmin() {
    try {
      this.loading = true;
      // eslint-disable-next-line max-len
      const data = (this.$refs.USER_FORM as Vue & { getUserFormData: () => void }).getUserFormData();
      await userService.createAdmin(data);
      this.loading = false;
      this.$store.commit('Snackbar/showNotification', { message: 'ดำเนินการเสร็จสิ้น', error: false });
      this.$router.push('/admins');
    } catch (e) {
      this.loading = false;
      this.$store.commit('Snackbar/showNotification', { message: e.message, error: true });
    }
  }
}
