
















































































































































































































































































































































































/* eslint-disable @typescript-eslint/camelcase */
/* eslint-disable prefer-destructuring */
import {
  Component,
  Vue,
  Prop,
  Watch,
} from 'vue-property-decorator';

@Component
export default class PromotionForm extends Vue {
  @Prop({ default: () => ({}) }) promotionData!: any;

  public promotionType: string = '';

  public promotionName: string = '';

  public promotionDetail: string = '';

  public turnover: number = 0;

  public startHour: string = '00';

  public startMinute: string = '00';

  public endHour: string = '00';

  public endMinute: string = '00';

  public giveAmount: number = 0;

  public giveType: string = '';

  public status: string = 'ACTIVATED';

  public minimumDeposit: number = 0;

  public limitPerDay: number = 0;

  public maximumGive: number = 0;

  public payback: number = 0;

  public exactAmount: number = 0;

  public maximumWithdraw: number = 0;

  public banner: File|null = null;

  public previewBanner: string = 'https://via.placeholder.com/150';

  public isStepTurnover: boolean = false;

  public stepTurnover: Array<{ maximum_withdraw: number; turnover_amount: number }> = [];

  public stepMinimumWithdraw: number = 0;

  public stepTarget: number = 0;

  public showingFor: string = 'PLAYER';

  public getFrom: string = 'SELF';

  // eslint-disable-next-line class-methods-use-this
  get hours() {
    return [...Array(24).keys()].map((value) => {
      if (value < 10) return `0${value}`;
      return value;
    });
  }

  // eslint-disable-next-line class-methods-use-this
  get minutes() {
    return [...Array(60).keys()].map((value) => {
      if (value < 10) return `0${value}`;
      return value;
    });
  }

  getPromotionFormData() {
    const formData = new FormData();
    formData.append('promotion_type', this.promotionType);
    formData.append('promotion_name', this.promotionName);
    formData.append('promotion_detail', this.promotionDetail);
    formData.append('turnover', String(this.turnover));
    formData.append('start_time', `${this.startHour}:${this.startMinute}`);
    formData.append('end_time', `${this.endHour}:${this.endMinute}`);
    formData.append('give_amount', String(this.giveAmount));
    formData.append('give_type', this.giveType);
    formData.append('status', this.status);
    formData.append('minimum_deposit', String(this.minimumDeposit));
    formData.append('limit_per_day', String(this.limitPerDay));
    formData.append('maximum_give', String(this.maximumGive));
    formData.append('payback', String(this.payback));
    formData.append('exact_amount', String(Number(this.exactAmount)));
    formData.append('maximum_withdraw', String(this.maximumWithdraw));
    formData.append('step_turnover', JSON.stringify(this.stepTurnover));
    formData.append('showing_for', String(this.showingFor));
    formData.append('get_from', String(this.getFrom));

    if (this.banner) {
      const { banner } = this;
      formData.append('banner', banner);
    }
    return formData;
  }

  @Watch('promotionData')
  onPromotionDataRecive(data: any) {
    this.promotionType = data.promotion_type;
    this.promotionName = data.promotion_name;
    this.promotionDetail = data.promotion_detail;
    this.turnover = data.turnover;
    this.giveAmount = data.give_amount;
    this.giveType = data.give_type;
    this.status = data.status;
    this.minimumDeposit = data.minimum_deposit;
    this.limitPerDay = data.limit_per_day;
    this.maximumGive = data.maximum_give;
    this.payback = data.payback;
    const startTime = data.start_time.split(':');
    const endTime = data.end_time.split(':');
    this.startHour = startTime[0];
    this.startMinute = startTime[1];
    this.endHour = endTime[0];
    this.endMinute = endTime[1];
    this.exactAmount = data.exact_amount;
    this.maximumWithdraw = data.maximum_withdraw;
    this.showingFor = data.showing_for;
    this.stepTurnover = data.step_turnover || [];
    this.getFrom = data.get_from;
    if (data.banner) {
      this.previewBanner = data.banner;
    }
    if (this.stepTurnover.length > 0) {
      this.isStepTurnover = true;
    }
  }

  onFileChange(file: any) {
    this.previewBanner = URL.createObjectURL(file);
  }

  addStep() {
    if (!this.stepMinimumWithdraw || !this.stepTarget) {
      this.$store.commit(
        'Snackbar/showNotification',
        { message: 'จำนวนถอนสูงสุดหรือจำนวนยอดที่ต้องทำต้องมากกว่า 0', error: true },
      );
      return;
    }
    this.stepTurnover.push({
      maximum_withdraw: this.stepMinimumWithdraw,
      turnover_amount: this.stepTarget,
    });
    this.stepMinimumWithdraw = 0;
    this.stepTarget = 0;
  }

  deleteStep(index: number) {
    this.stepTurnover.splice(index, 1);
  }

  @Watch('promotionType')
  onPromotionTypeChange(data: string) {
    if (data === 'FREE_CREDIT') {
      if (this.showingFor !== 'BOTH') {
        this.showingFor = 'ADMIN';
      }
    } else {
      this.showingFor = 'PLAYER';
    }
  }

  @Watch('showingFor')
  onShowingForChange(data: string) {
    if (data === 'BOTH') this.getFrom = 'LINE';
    if (data === 'PLAYER') this.getFrom = 'SELF';
  }
}
