














































import { Component, Vue } from 'vue-property-decorator';
import PlayerService from '@/services/PlayerService';

const playerService = new PlayerService();

@Component
export default class SystemSetting extends Vue {
  public loading: boolean = false;

  // eslint-disable-next-line class-methods-use-this
  async changeBetConfigSetting(value: any) {
    try {
      await playerService.setBetConfig(value);
      this.$store.commit('Snackbar/showNotification', { message: 'ดำเนินการเสร็จสิ้น', error: false });
    } catch (e) {
      this.$store.commit('Snackbar/showNotification', { message: e.message, error: true });
    }
  }
}
