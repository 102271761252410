/* eslint-disable */
import {
  VuexModule,
  Module,
  Action,
  Mutation,
} from 'vuex-module-decorators';
import router from '@/router';
import AuthService from '@/services/AuthService';
import Pusher from 'pusher-js';

const authService = new AuthService();

interface LoginPayload {
  isLoggedIn: boolean;
  username: string;
  name: string;
  token: string;
  permissions: { [key: string]: any };
  userId: string;
}

@Module({ namespaced: true })
export default class Auth extends VuexModule {
  public isLoggedIn: boolean = false;

  public username: string = '';

  public name: string = '';

  public userId: string = '';

  public permissions: { [key: string]: any } = {
    Dashboard: false,
    DepositRequest: false,
    WithdrawRequest: false,
    Transaction: false,
    DepositTransaction: false,
    WithdrawTransaction: false,
    BetTransaction: false,
    RewardTransaction: false,
    AdjustmentTransaction: false,
    PromotionTransaction: false,
    RefundTransaction: false,
    PlayerList: false,
    AdminList: false,
    PromotionList: false,
    BankList: false,
    Summary: false,
    PopularGames: false,
    Share: false,
    TokenManage: false,
    SystemSetting: false,
  };

  public token: string = '';

  @Mutation
  public setLoggedIn(payload: LoginPayload) {
    this.isLoggedIn = payload.isLoggedIn;
    this.username = payload.username;
    this.name = payload.name;
    this.token = payload.token;
    this.userId = payload.userId;
    this.permissions = payload.permissions
  }

  @Action({ rawError: true })
  public subscibeNotification() {
    const pusher = new Pusher('5f69d7423530aabce718', { cluster: 'ap1' });
    const channel = pusher.subscribe('notification');
    channel.bind(`admin-${this.userId}-update`, async () => {
      this.context.dispatch('Auth/logout', {}, { root: true });
      router.push({ name: 'Login' });
    });
  }

  @Action({ rawError: true })
  public async login(payload: { username: string; password: string }): Promise<void> {
    const { data } = await authService.login(payload.username, payload.password);
    console.log(data);
    if (data.token) {
      console.log(data);
      this.context.commit('setLoggedIn', {
        name: data.name,
        username: data.username,
        isLoggedIn: true,
        token: data.token,
        userId: data.user_id,
        permissions: data.permissions,
      });
      await authService.storeTokenInSessionStorage(data.token);
      this.context.dispatch('subscibeNotification');
    }
  }

  @Action async autoLogin(): Promise<void> {
    try {
      const { data } = await authService.checkToken();
      const token = await authService.getTokenFormSessionStorage();
      this.context.commit('setLoggedIn', {
        name: data.name,
        username: data.username,
        isLoggedIn: true,
        userId: data._id,
        token,
        permissions: data.permissions,
      });
      this.context.dispatch('subscibeNotification');
    } catch (e) {
      console.log('error auto login', e);
    }
  }

  @Action
  public async logout() {
    await authService.removeTokenFormSessionStorage();
    this.context.commit('setLoggedIn', {
      username: '',
      name: '',
      token: '',
      isLoggedIn: false,
    });
  }

  @Action
  public async logoutAndRevokeToken() {
    this.context.commit('setLoggedIn', {
      username: '',
      name: '',
      token: '',
      isLoggedIn: false,
    });
    await authService.logout();
    await authService.removeTokenFormSessionStorage();
  }
}
 