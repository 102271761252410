/* eslint-disable */
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios';
import router from '@/router';
import store from '@/store';

export default class HttpRequest {
  private http: AxiosInstance;

  constructor() {
    this.http = axios.create();
    this.http.interceptors.request.use(this.requestInterceptor);
    this.http.interceptors.response.use(
      async (response) => response,
      this.responseErrorInterceptor,
    );
  }

  private requestInterceptor = async (config: AxiosRequestConfig) => {
    const token = await sessionStorage.getItem('easy-333-admin.com');
    if (token) {
      config.headers = {
        Authorization: `Bearer ${token}`,
      };
    }
    return config;
  }

  private responseErrorInterceptor = async (error: AxiosError) => {
    if (error.response?.status === 401) {
      store.dispatch('Auth/logout');
      router.push('/');
    }
    if (error.response?.data) {
      const { message } = error.response.data;
      console.log('type of message', typeof message);
      console.log(message);
      return Promise.reject(new Error(message));
    }
    return Promise.reject(new Error(error.message));
  }

  public get(path: string) {
    return this.http.get(path);
  }

  public post(path: string, payload: object|undefined) {
    return this.http({
      method: 'POST',
      data: payload,
      url: path,
    });
  }

  public patch(path: string, payload: object|undefined) {
    return this.http({
      method: 'PATCH',
      data: payload,
      url: path,
    });
  }

  public put(path: string, payload: object|undefined) {
    return this.http({
      method: 'PUT',
      data: payload,
      url: path,
    });
  }

  public delete(path: string, payload: object|undefined) {
    return this.http({
      method: 'DELETE',
      data: payload,
      url: path,
    });
  }
}
