









































import { Component, Vue } from 'vue-property-decorator';
import PromotionForm from '@/components/PromotionForm.vue';
import PromotionService from '@/services/PromotionService';

const promotionService = new PromotionService();

@Component({
  components: {
    PromotionForm,
  },
})
export default class CreatePromotion extends Vue {
  public loading: boolean = false;

  async createPromotion() {
    try {
      this.loading = true;
      // eslint-disable-next-line max-len
      const data = (this.$refs.PROMOTION_FORM as Vue & { getPromotionFormData: () => void }).getPromotionFormData();
      console.log('data', data);
      await promotionService.createPromotion(data);
      this.loading = false;
      this.$store.commit('Snackbar/showNotification', { message: 'สร้างโปรโมชั่นเรียบร้อยแล้ว', error: false });
      this.$router.push('/promotions');
    } catch (e) {
      this.loading = false;
      this.$store.commit('Snackbar/showNotification', { message: e.message, error: true });
    }
  }
}
