





























































import Auth from '@/connector/Auth.vue';
import { Component, Vue, Watch } from 'vue-property-decorator';

@Component({
  components: {
    Auth,
  },
})
export default class Login extends Vue {
  public username: string = '';

  public password: string = '';

  @Watch('username')
  onUsernameChange(value: string) {
    this.username = value.toLowerCase();
  }

  get loginData() {
    return {
      username: this.username,
      password: this.password,
    };
  }
}
