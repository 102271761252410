




































































import {
  Component,
  Vue,
  Prop,
  Watch,
} from 'vue-property-decorator';

@Component
export default class UserForm extends Vue {
  @Prop({ default: () => ({}) }) UserData!: any;

  public name: string = '';

  public password: string = '';

  public username: string = '';

  public permissions: { [key: string]: any } = {
    Dashboard: false,
    DepositRequest: false,
    WithdrawRequest: false,
    Transaction: false,
    DepositTransaction: false,
    WithdrawTransaction: false,
    BetTransaction: false,
    RewardTransaction: false,
    AdjustmentTransaction: false,
    PromotionTransaction: false,
    RefundTransaction: false,
    PlayerList: false,
    EditPlayer: false,
    PlayerDetail: false,
    AdminList: false,
    CreateAdmin: false,
    EditAdmin: false,
    PromotionList: false,
    CreatePromotion: false,
    EditPromotion: false,
    BankList: false,
    CreateBank: false,
    EditBank: false,
    Summary: false,
    PopularGames: false,
    Share: false,
    ShareDetail: false,
    TokenManage: false,
    SystemSetting: false,
    ChangePassword: true,
    NotAllow: true,
    WinLoseSummary: true,
  };

  // eslint-disable-next-line class-methods-use-this
  public get permissionsPages() {
    return [
      { name: 'ข้อมูลสรุป', key: 'Dashboard' },
      { name: 'รายการแจ้งฝาก', key: 'DepositRequest' },
      { name: 'รายการแจ้งถอน', key: 'WithdrawRequest' },
      { name: 'รายการธุรกรรมทั้งหมด', key: 'Transaction' },
      { name: 'รายการธุรกรรมรายการฝาก', key: 'DepositTransaction' },
      { name: 'รายการธุรกรรมรายการถอน', key: 'WithdrawTransaction' },
      { name: 'รายการธุรกรรมรายการการแทง', key: 'BetTransaction' },
      { name: 'รายการธุรกรรมรายการแก้ไขผลรางวัล', key: 'RewardTransaction' },
      { name: 'รายการธุรกรรมรายการผลรางวัล', key: 'AdjustmentTransaction' },
      { name: 'รายการธุรกรรมโปรโมชั่น', key: 'PromotionTransaction' },
      { name: 'รายการธุรกรรมคืนยอดเสีย', key: 'RefundTransaction' },
      { name: 'ยอดได้-เสีย', key: 'WinLoseSummary' },
      { name: 'ผู้เล่น', key: 'PlayerList' },
      { name: 'ผู้ช่วย', key: 'AdminList' },
      { name: 'โปรโมชั่น', key: 'PromotionList' },
      { name: 'บัญชีธนาคาร', key: 'BankList' },
      { name: 'สรุปยอดรายวัน', key: 'Summary' },
      { name: 'เกมส์ยอดนิยม', key: 'PopularGames' },
      { name: 'ระบบแนะนำ', key: 'Share' },
      { name: 'จัดการการใช้งานระบบ', key: 'TokenManage' },
      { name: 'ตั้งค่าระบบ', key: 'SystemSetting' },
    ];
  }

  public getUserFormData() {
    return {
      name: this.name,
      username: this.username,
      password: this.password ? this.password : undefined,
      permissions: this.permissions,
    };
  }

  @Watch('UserData')
  onUserDataRecive(data: any) {
    this.name = data.name;
    this.username = data.username;
    this.permissions = data.permissions;
  }

  @Watch('permissions.PlayerList', { deep: true })
  onPlayerListChange(data: any) {
    this.permissions.EditPlayer = data;
    this.permissions.PlayerDetail = data;
  }

  @Watch('permissions.AdminList', { deep: true })
  onAdminListChange(data: any) {
    this.permissions.CreateAdmin = data;
    this.permissions.EditAdmin = data;
  }

  @Watch('permissions.PromotionList', { deep: true })
  onPromotionListChange(data: any) {
    this.permissions.CreatePromotion = data;
    this.permissions.EditPromotion = data;
  }

  @Watch('permissions.BankList', { deep: true })
  onBankListChange(data: any) {
    this.permissions.CreateBank = data;
    this.permissions.EditBank = data;
  }

  @Watch('permissions.Share', { deep: true })
  onShareChange(data: any) {
    this.permissions.ShareDetail = data;
  }
}
