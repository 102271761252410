



















import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';

const Auth = namespace('Auth');

@Component
export default class AuthConnector extends Vue {
  @Auth.State
  public isLoggedIn!: boolean;

  @Auth.State
  public token!: string;

  @Auth.State
  public username!: string;

  @Auth.State
  public name!: string;

  @Auth.State
  public permissions!: object;

  public loading: boolean = false;

  @Auth.Action
  public login!: (payload: object) => {}

  @Auth.Action
  public logoutAndRevokeToken!: () => {}

  public async loginFunction(payload: { username: string; password: string }) {
    this.loading = true;
    try {
      await this.login(payload);
      this.$router.push('/deposit');
    } catch (e) {
      this.$store.commit('Snackbar/showNotification', { message: e.message, error: true });
    }
    this.loading = false;
  }

  public async logoutFunction() {
    await this.logoutAndRevokeToken();
    this.$router.push('/');
  }
}
