var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('default-layout',[_c('h1',{staticClass:"mb-2 font-weight-regular"},[_vm._v("รายการเกมส์ยอดนิยม")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('auth',{scopedSlots:_vm._u([{key:"loggedIn",fn:function(ref){
var token = ref.token;
return [_c('v-card',{attrs:{"color":"grey lighten-5","flat":""}},[_c('v-progress-linear',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],attrs:{"indeterminate":""}}),_c('v-card-text',[_c('base-data-table',{ref:"PROMOTION_TABLE",attrs:{"data-url":_vm.userPopularGamesUrl,"request-header":{
                  'authorization': ("Bearer " + token)
                },"filters":{
                  paginate: true,
                },"headers":[
                  { text: 'เกมส์', value: 'game' },
                  { text: 'จำนวนคลิก', value: 'count', sortable: true, },
                  { text: 'คลิกครั้งล่าสุด', value: 'updated_at', sortable: true } ]},scopedSlots:_vm._u([{key:"updated_at",fn:function(ref){
                var props = ref.props;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(props.updated_at))+" ")]}}],null,true)})],1)],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }