





















































































































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import PlayerService from '@/services/PlayerService';

const playerService = new PlayerService();
/* eslint-disable @typescript-eslint/camelcase */
@Component
export default class EditPlayer extends Vue {
  public accountName: string = '';

  public bank: string = '';

  public accountNumber: string = '';

  public lineId: string = '';

  // public password: string = '';

  public username: string = '';

  public loading: boolean = false;

  public isAgent: boolean = false;

  public share_config = {
    // eslint-disable-next-line @typescript-eslint/camelcase
    share_percent: 0,
    overhead: 0,
  };

  async created() {
    const { data } = await playerService.getPlayerById(this.$route.params.id);
    this.accountName = data.account_name;
    this.accountNumber = data.account_number;
    this.bank = data.bank;
    this.username = data.username;
    this.lineId = data.line_id;
    this.isAgent = data.isAgent;
    this.share_config = data.share_config;
  }

  async updatePlayer() {
    try {
      this.loading = true;
      await playerService.updatePlayer(this.$route.params.id, {
        account_name: this.accountName ? this.accountName : undefined,
        account_number: this.accountNumber ? this.accountNumber : undefined,
        bank: this.bank ? this.bank : undefined,
        username: this.username ? this.username : undefined,
        isAgent: this.isAgent,
        share_config: this.share_config,
        // password: this.password ? this.password : undefined,
        line_id: this.lineId ? this.lineId : undefined,
      });
      this.loading = false;
      this.$store.commit('Snackbar/showNotification', { message: 'ดำเนินการเสร็จสิ้น', error: false });
    } catch (e) {
      this.loading = false;
      this.$store.commit('Snackbar/showNotification', { message: e.message, error: true });
    }
  }
}
