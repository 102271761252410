









































import { Component, Vue } from 'vue-property-decorator';
import BaseDataTable from '@/components/BaseDataTable.vue';
import moment from 'moment';
import Auth from '@/connector/Auth.vue';

@Component({
  components: {
    BaseDataTable,
    Auth,
  },
})
export default class PopularGames extends Vue {
  public loading: boolean = false;

  // eslint-disable-next-line class-methods-use-this
  get userPopularGamesUrl() {
    return `${process.env.VUE_APP_API_ENDPOINT}/users/stats/popular-games`;
  }
}
