var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('default-layout',[_c('h1',{staticClass:"mb-2 font-weight-regular",staticStyle:{"font-size":"2.4rem"}},[_vm._v(" สรุปยอดส่วนแนะนำ ")]),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('auth',{scopedSlots:_vm._u([{key:"loggedIn",fn:function(ref){
var token = ref.token;
return [_c('v-card',{attrs:{"color":"grey lighten-5","flat":""}},[_c('v-card-title',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"2","lg":"2","xl":"1"}},[_c('div',{staticClass:"d-flex align-center",staticStyle:{"height":"100%"}},[_c('div',{staticStyle:{"font-size":"1.15rem"}},[_vm._v(" เลือกช่วงเวลา ")])])]),_c('v-col',{attrs:{"cols":"12","md":"4","xl":"3"}},[_c('v-select',{attrs:{"items":_vm.monthsOption,"filled":"","rounded":"","dense":"","hide-details":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1),_c('v-spacer'),_c('v-col',{attrs:{"cols":"12","md":"3","xl":"3"}},[_c('v-select',{attrs:{"placeholder":"ประเภทผู้ใช้","filled":"","rounded":"","hide-details":"","dense":"","items":[
                      { text: 'ทั้งหมด', value: '' },
                      { text: 'ตัวแทน', value: 'agent' },
                      { text: 'ผู้เล่น', value: 'player' } ]},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}})],1)],1)],1),_c('v-progress-linear',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],attrs:{"indeterminate":""}}),_c('div',{staticClass:"px-5"},[_c('span',{staticClass:"red--text"},[_vm._v(" *** ท่านสามารถเพิ่มตัวแทนได้ที่หน้าแก้ไขผู้เล่น *** ")])]),_c('v-card-text',[_c('base-data-table',{ref:"SHARE_TABLE",attrs:{"data-url":_vm.shareUrl,"request-header":{
                  'authorization': ("Bearer " + token)
                },"filters":{
                  paginate: true,
                  date: _vm.date,
                  type: _vm.type,
                },"headers":[
                  { text: 'ชื่อผู้ใช้', value: 'username' },
                  { text: 'ชื่อบัญชี', value: 'account_name' },
                  { text: 'เลขที่บัญชี', value: 'account_number' },
                  { text: 'จำนวน Downline', value: 'total_player' },
                  { text: 'ยอดฝาก Downline', value: 'total_deposit', dataType: 'number' },
                  { text: 'ยอดถอน Downline', value: 'total_withdraw', dataType: 'number' },
                  {
                    text: 'ยอดฝาก - ยอดถอน',
                    value: 'deposit_withdraw_summary',
                    dataType: 'number',
                  },
                  {
                    text: 'ยอดคงเหลือในกระเป๋า Downline',
                    value: 'total_balance',
                    dataType: 'number'
                  },
                  { text: 'ยอดเสียจริง', value: 'total_loss' },
                  { text: '% หัวคิว', value: 'overhead_percent', dataType: 'number' },
                  { text: 'ยอดหัวคิว', value: 'overhead_amount', dataType: 'number' },
                  {
                    text: 'ยอดหลังหักหัวคิว',
                    value: 'amount_after_overhead',
                    dataType: 'number'
                  },
                  { text: 'แบ่งรับ', value: 'share_percent', dataType: 'number' },
                  { text: 'ยอดส่วนแบ่ง', value: 'final_amount', dataType: 'number' },
                  { text: 'ตัวเลือก', value: 'options' }
                ]},scopedSlots:_vm._u([{key:"username",fn:function(ref){
                var props = ref.props;
return [_vm._v(" "+_vm._s(_vm._f("hiddenUsername")(props.user.username))+" ")]}},{key:"account_name",fn:function(ref){
                var props = ref.props;
return [_vm._v(" "+_vm._s(props.user.account_name)+" ")]}},{key:"account_number",fn:function(ref){
                var props = ref.props;
return [_vm._v(" "+_vm._s(props.user.account_number)+" ")]}},{key:"overhead_percent",fn:function(ref){
                var overhead_percent = ref.props.overhead_percent;
return [_vm._v(" "+_vm._s(overhead_percent)+"% ")]}},{key:"share_percent",fn:function(ref){
                var share_percent = ref.props.share_percent;
return [_vm._v(" "+_vm._s(share_percent)+"% ")]}},{key:"deposit_withdraw_summary",fn:function(ref){
                var deposit_withdraw_summary = ref.props.deposit_withdraw_summary;
return [_c('span',{class:((_vm.getColor(deposit_withdraw_summary)) + "--text")},[_vm._v(" "+_vm._s(_vm._f("numberWithSeperator")(Number(deposit_withdraw_summary)))+" ")])]}},{key:"overhead_amount",fn:function(ref){
                var overhead_amount = ref.props.overhead_amount;
return [_c('span',{class:((_vm.getColor(overhead_amount)) + "--text")},[_vm._v(" "+_vm._s(_vm._f("numberWithSeperator")(Number(overhead_amount)))+" ")])]}},{key:"amount_after_overhead",fn:function(ref){
                var amount_after_overhead = ref.props.amount_after_overhead;
return [_c('span',{class:((_vm.getColor(amount_after_overhead)) + "--text")},[_vm._v(" "+_vm._s(_vm._f("numberWithSeperator")(Number(amount_after_overhead)))+" ")])]}},{key:"final_amount",fn:function(ref){
                var final_amount = ref.props.final_amount;
return [_c('span',{class:((_vm.getColor(final_amount)) + "--text")},[_vm._v(" "+_vm._s(_vm._f("numberWithSeperator")(Number(final_amount)))+" ")])]}},{key:"options",fn:function(ref){
                var props = ref.props;
return [_c('v-btn',{attrs:{"color":"primary","depressed":"","to":{ name: 'ShareDetail', params: { id: props._id } }}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v(" fas fa-search ")]),_vm._v(" ดูรายละเอียด ")],1)]}}],null,true)})],1)],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }