




















import {
  Component,
  Vue,
  Prop,
  Emit,
} from 'vue-property-decorator';

@Component({})
export default class SummaryCard extends Vue {
  @Prop({ type: String }) text?: number;

  @Prop({ type: Number }) amount?: number;

  @Prop({ type: String }) unit?: number;

  @Prop({ type: Boolean, default: true }) withSeperator?: boolean;
}
