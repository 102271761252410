var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('default-layout',[_c('h1',{staticClass:"font-weight-regular",staticStyle:{"font-size":"2.4rem"}},[_vm._v(" สรุปยอดส่วนแนะนำ "+_vm._s(_vm.data.user.account_name)+" ")]),_c('p',{staticClass:"mb-2",staticStyle:{"font-size":"1.5rem"}},[_vm._v("ประจำงวดที่ "+_vm._s(_vm.data.period))]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-card',{attrs:{"dark":"","color":"green","flat":""}},[_c('div',{staticClass:"pa-5"},[_c('center',[_c('span',[_vm._v("ยอดฝากทั้งหมด")]),_c('h1',[_vm._v(" "+_vm._s(Number(_vm.data.total_deposit).toFixed(2))+" ")]),_c('span',[_vm._v("บาท")])])],1)])],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-card',{attrs:{"dark":"","color":"red","flat":""}},[_c('div',{staticClass:"pa-5"},[_c('center',[_c('span',[_vm._v("ยอดถอนทั้งหมด")]),_c('h1',[_vm._v(" "+_vm._s(Number(_vm.data.total_withdraw).toFixed(2))+" ")]),_c('span',[_vm._v("บาท")])])],1)])],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"dark":"","color":"primary","flat":""}},[_c('div',{staticClass:"pa-5"},[_c('center',[_c('span',[_vm._v("สรุป (ยอดฝาก - ยอดถอน)")]),_c('h1',[_vm._v(" "+_vm._s(Number(_vm.data.deposit_withdraw_summary).toFixed(2))+" ")]),_c('span',[_vm._v("บาท")])])],1)])],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('auth',{scopedSlots:_vm._u([{key:"loggedIn",fn:function(ref){
var token = ref.token;
return [_c('v-card',{attrs:{"color":"grey lighten-5","flat":""}},[_c('v-card-title'),_c('v-progress-linear',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],attrs:{"indeterminate":""}}),_c('v-card-text',[_c('base-data-table',{ref:"SHARE_DETAIL_TABLE",attrs:{"data-url":_vm.shareDetailUrl,"request-header":{
                  'authorization': ("Bearer " + token)
                },"filters":{
                  paginate: true,
                },"headers":[
                  { text: 'username', value: 'username' },
                  { text: 'ชื่อ-นามสกุล', value: 'account_name' },
                  { text: 'ยอดในกระเป๋า ณ เวลาที่คำนวน', value: 'current_balance' },
                  { text: 'ยอดฝากเดือนนี้', value: 'current_month_deposit', dataType: 'number' },
                  { text: 'ยอดถอนเดือนนี้', value: 'current_month_withdraw', dataType: 'number' },
                  { text: 'สรุป', value: 'summary', dataType: 'number' },
                  { text: 'ตัวเลือก', value: 'options' }
                ]},scopedSlots:_vm._u([{key:"username",fn:function(ref){
                var props = ref.props;
return [_vm._v(" "+_vm._s(_vm._f("hiddenUsername")(props.user.username))+" ")]}},{key:"options",fn:function(ref){
                var props = ref.props;
return [_c('v-btn',{attrs:{"color":"primary","depressed":"","to":{
                      name: 'Transaction',
                      query: { player: props.user._id, range: _vm.dateRange }
                    }}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v(" fas fa-search ")]),_vm._v(" ดูรายการธุรกรรม ")],1)]}}],null,true)})],1)],1)]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }