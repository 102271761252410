var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.loading)?_c('div',[_c('v-simple-table',[_c('thead',[_c('tr',_vm._l((_vm.headers),function(header,index){return _c('td',{key:("table-header-" + index),class:header.align ? ("text-" + (header.align)) : '',style:(("user-select: none; " + (header.sortable ? 'cursor: pointer;' : ''))),on:{"click":function($event){header.sortable
            && (_vm.sortBy === header.value ? _vm.setOrder() : _vm.setSortBy(header.value))}}},[_vm._v(" "+_vm._s(header.text)+" "),(header.sortable && _vm.sortBy === header.value)?_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-arrow-"+_vm._s(_vm.order === 'asc' ? 'down' : 'up')+" ")]):_vm._e()],1)}),0)]),(_vm.items.length > 0)?_c('tbody',_vm._l((_vm.items),function(item,rowIndex){
            var _obj;
return _c('tr',{key:("row-" + rowIndex),class:( _obj = {}, _obj[_vm.highlightColorClass] = _vm.highlightColumn(item), _obj )},_vm._l((_vm.headers),function(header,colIndex){return _c('td',{key:("row-" + rowIndex + "-column-" + colIndex)},[(_vm.$scopedSlots[header.value])?_vm._t(header.value,null,{"props":item}):[(header.dataType === 'number')?_c('span',[_vm._v(" "+_vm._s(item[header.value] ? Number(item[header.value]).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",") : 0)+" ")]):_c('span',[_vm._v(" "+_vm._s(item[header.value])+" ")])]],2)}),0)}),0):_c('tbody',[_c('tr',[(_vm.$slots['data-empty'])?_vm._t("data-empty"):_c('td',{attrs:{"colspan":_vm.headers.length}},[_c('center',[(_vm.error)?_c('v-icon',[_vm._v(" mdi-exclamation ")]):_vm._e(),_vm._v(" "+_vm._s(_vm.error ? _vm.errorMessage : _vm.noDataText)+" ")],1)],1)],2)]),_c('tfoot',[_vm._t("table-footer")],2)]),_c('v-card',{staticClass:"mt-1",attrs:{"flat":""}},[_c('v-card-text',[_c('v-row',{attrs:{"no-gutters":"","justify":"end","align":"center"}},[_c('v-col',{attrs:{"xl":"2","md":"3","sm":"5","cols":"12"}},[_c('v-row',{staticClass:"align-center",attrs:{"no-gutters":""}},[_c('v-col',{attrs:{"cols":"6"}},[_c('span',{staticClass:"caption"},[_vm._v(" จำนวนข้อมูลต่อหน้า : ")])]),_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',{attrs:{"filled":"","rounded":"","dense":"","hide-details":"","items":[
                  10,
                  20,
                  30
                ]},model:{value:(_vm.itemPerPage),callback:function ($$v) {_vm.itemPerPage=$$v},expression:"itemPerPage"}})],1)],1)],1),_c('v-col',{attrs:{"xl":"1","md":"2","sm":"3","cols":"12"}},[_c('p',{staticClass:"subtitle-1 paginate-info my-3"},[_vm._v(" "+_vm._s(_vm.paginateInfo)+" ")])]),_c('v-col',{attrs:{"md":"3","sm":"4","cols":"12"}},[_c('v-pagination',{attrs:{"color":"blue","total-visible":7,"length":_vm.totalPages},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)],1)],1)],1):_c('v-skeleton-loader',{attrs:{"type":"table-tbody"}})}
var staticRenderFns = []

export { render, staticRenderFns }