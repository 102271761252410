/* eslint-disable */
import { AppService } from './AbstractService';

export default class AuthService extends AppService {
  public async login(username: string, password: string): Promise<any> {
    const payload = {
      username,
      password,
    };
    return this.makeRequest('POST', '/auth/admins', payload);
  }

  public async checkToken() {
    const response = this.makeRequest('POST', '/auth/check-admin-token');
    return response;
  }

  public async storeTokenInSessionStorage(token: string): Promise<void> {
    await this.sessionStorage.setItem('easy-333-admin.com', token);
  }

  public async getTokenFormSessionStorage(): Promise<string|null> {
    const username = await this.sessionStorage.getItem('easy-333-admin.com');
    return username;
  }

  public async removeTokenFormSessionStorage(): Promise<void> {
    await this.sessionStorage.removeItem('easy-333-admin.com');
  }

  public async logout(): Promise<boolean> {
    const { data } = await this.makeRequest('POST', '/auth/admins/logout');
    return data;
  }

  public async revoke(revokeData: { user_id: string; }): Promise<boolean> {
    const { data } = await this.makeRequest('POST', '/auth/revoke', revokeData);
    return data;
  }

  public async getActiveTokenList() {
    const { data } = await this.makeRequest('GET', '/auth/current-active-token');
    return data;
  }

  public async changePassword(
    oldPassword: string,
    newPassword: string,
    confirmNewPassword: string,
  ) {
    const payload = {
      old_password: oldPassword,
      new_password: newPassword,
      confirm_new_password: confirmNewPassword,
    };
    return this.makeRequest('PATCH', '/admins/change-password', payload);
  }
}
