import Vue from 'vue';
import moment from 'moment';
import vuetify from '@/plugins/vuetify';
import '@/plugins/vue-frag';
import '@/plugins/debounce';
import DefaultLayout from '@/layout/DefaultLayout.vue';
import store from '@/store';
import App from './App.vue';
import router from './router';

Vue.config.productionTip = false;

Vue.component('default-layout', DefaultLayout);

Vue.prototype.$assetsEndpoint = `${process.env.VUE_APP_ASSET_ENDPOINT}/assets`;

Vue.filter('statusToTH', (value: string) => {
  if (value === 'PENDING') return 'อยู่ระหว่างดำเนินงาน';
  if (value === 'APPROVED') return 'สำเร็จ';
  if (value === 'REJECTED') return 'ปฏิเสธ';
  return '';
});

Vue.filter('formatDate', (value: any) => moment(value).format('YYYY-MM-DD HH:mm:ss'));

Vue.filter('numberWithSeperator', (value: number) => (value ? value.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',') : 0));

Vue.filter('hiddenUsername', (value: string) => {
  const arrayOfChar = value.split('');
  arrayOfChar[2] = 'X';
  arrayOfChar[3] = 'X';
  arrayOfChar[4] = 'X';
  arrayOfChar[5] = 'X';
  return arrayOfChar.join('');
});

store.dispatch('Notification/initiateNotificationState');
store.dispatch('Notification/subscribeNotification');
store.dispatch('Auth/autoLogin').then(() => {
  new Vue({
    router,
    store,
    vuetify,
    render: (h) => h(App),
  }).$mount('#app');
});
