














































































import {
  Vue,
  Component,
  Emit,
  Prop,
  Watch,
} from 'vue-property-decorator';
import { PropType } from 'vue';

@Component
export default class BaseDateRangeSearch extends Vue {
  @Prop({ type: Array as PropType<string[]> }) private readonly defaultDate!: Array<string>;

  public dateMenu: boolean = false;

  public dates: Array<string> = ['', ''];

  get dateString() {
    return this.dates.join(' - ');
  }

  @Emit()
  search() {
    return this.dates.toString();
  }

  @Watch('defaultDate', { immediate: true })
  onDefaultDateChange(value: string[]) {
    console.log('value', value);
    this.dates = value;
  }
}
