





































































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import BaseDataTable from '@/components/BaseDataTable.vue';
import moment from 'moment';
import Auth from '@/connector/Auth.vue';
import BaseDateRangeSearch from '@/components/BaseDateRangeSearch.vue';
import ShareService from '@/services/ShareService';

const shareService = new ShareService();

@Component({
  components: {
    BaseDataTable,
    Auth,
    BaseDateRangeSearch,
  },
})
export default class ShareDetail extends Vue {
  public loading: boolean = false;

  public date: string = moment().format('YYYY-MM');

  public type: string = '';

  public data: any = {};

  // eslint-disable-next-line class-methods-use-this
  get monthsOption() {
    const currentMonth = moment();
    const maximumData = moment().subtract(6, 'month');
    const options = [];
    while (currentMonth > maximumData) {
      options.push(currentMonth.format('YYYY-MM'));
      currentMonth.subtract(1, 'month');
    }
    return options;
  }

  // eslint-disable-next-line class-methods-use-this
  get shareDetailUrl() {
    return `${process.env.VUE_APP_API_ENDPOINT}/shares/detail/${this.$route.params.id}`;
  }

  // eslint-disable-next-line class-methods-use-this
  public getColor(amount: number) {
    if (amount > 0) return 'green';
    return 'red';
  }

  get dateRange() {
    const startDate = moment(this.date).startOf('month').format('YYYY-MM-DD');
    const endDate = moment(this.date).endOf('month').format('YYYY-MM-DD');
    return [startDate, endDate].toString();
  }

  // eslint-disable-next-line class-methods-use-this
  public formatDate(date: string) {
    return moment(date).format('DD/MM/YYYY');
  }

  async created() {
    const { data } = await shareService.getShare(this.$route.params.id);
    this.data = { ...data };
  }
}
