/* eslint-disable @typescript-eslint/camelcase */
import { AppService } from './AbstractService';

export default class BankService extends AppService {
  public createBank(data: {
    bank: string;
    account_number: string;
    name: string;
  }) {
    return this.makeRequest('POST', '/banks', data);
  }

  public getAllBank() {
    return this.makeRequest('GET', '/banks');
  }

  public getBankById(id: string) {
    return this.makeRequest('GET', `/banks/${id}`);
  }

  public editBank(data: {
    bank_id: string;
    bank: string;
    account_number: string;
    name: string|undefined;
  }) {
    return this.makeRequest('PUT', '/banks', data);
  }

  public deleteBank(id: string) {
    return this.makeRequest('DELETE', `/banks/${id}`);
  }

  public healthCheck(bank: string, acc_num: string) {
    return this.makeRequest('POST', '/banks/check', { bank, acc_num });
  }

  public getBankConfig() {
    return this.makeRequest('GET', '/banks/config');
  }

  public setBankConfig(data: { auto_create_request: boolean }) {
    return this.makeRequest('POST', '/banks/config', data);
  }
}
